import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Manage,
  ManageBox,
  Table,
  TableTitle,
  Text,
  TextBlack,
  TextGreyBold,
  Modal,
  Confirm,
  Overflow,
  FilledButton,
  Block,
  Overlay,
  Result,
  Check,
  SecondaryBtnHigh,
  ButtonsWrap
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteUser } from 'store/slices/users';
import styled from 'styled-components';
import { func, array, any, bool } from 'prop-types';
import { ReactComponent as Edit } from 'assets/images/edit.svg';
import { ReactComponent as Delete } from 'assets/images/trash.svg';
import { ReactComponent as UserIcon } from 'assets/images/user-icon.svg';
import { ReactComponent as Nav } from 'assets/images/navigation.svg';
import { ReactComponent as Imperson } from 'assets/images/imperson.svg';
import { ReactComponent as Resend } from 'assets/images/resend.svg';
import Success from 'base/components/Success';
import Responsive from 'context/responsive';
import FormUser from './FormUser';
import network from 'base/network';

const ManageRight = styled(Manage)`
  right: 15px;
  cursor: pointer;
  @media (max-width: 768px) {
    right: 0;
  }
`;

const TableNew = styled(Table)`
  box-shadow: none;
  td.checkbox {
    padding-top: 24px;
  }
  th:nth-child(2) {
      padding-left: 20px;
  }
  th:first-of-type {
    width: 5px;
  }
  td {
    z-index: auto;
    vertical-align: top;  
    padding-top: 12px;
  }
  td:last-of-type {
    position: static;
    padding-top: 28px;
  }
  p {
    font-weight: 500;
  }
  @media (max-width: 768px) {
    padding: 0 10px;
    th:first-of-type {
      padding-left: 12px;
    }
    td {
      border-right: none!important;
    }
    p {
      margin: 0 0 10px;
    }
    .dhZMDH {
      min-width: 120px;
      width: 90%;
    }
    .check-all+span {
      display: none;
    }
    tr::after {
     left: 8px
    }
`;

const ManageBoxRight = styled(ManageBox)`
  right: 15px;
  padding: 0;
  a {
    padding: 8px 15px;
    &:hover {
      border-radius: 0;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
    }
  }
`;

const UserCell = styled.td`
  padding: 10px;
  p {
    font-weight: 500;
    margin-left: 10px;
  }
  svg {
    float: left;
    margin: 5px 10px;
  }
`;

const Detected = styled(TextGreyBold)`
  padding: 5px 10px;
  width: fit-content;
  border-radius: 11.5px;
  background: #afffa2;
  font-size: 0.75rem;
`;

const Notdetected = styled(TextGreyBold)`
  padding: 5px 10px;
  width: fit-content;
  border-radius: 11.5px;
  background: #fdeaea;
  font-size: 0.75rem;
`;

const Td = styled.td`
  width: 30%;
  @media (max-width: 968px) {
    width: auto;
    .css-16pkpmb-SelectContainer {
      width: 150px;
    }
  }
`;

const Popup = styled(Block)`
  margin: -2% auto 0;
  padding: 20px 0;
  @media (max-width: 768px) {
    p {
      margin: 2px 0;
    }
  }
`;

const OverManage = styled(Overlay)`
  z-index: 19;
  background: inherit;
`;

const Results = styled(Result)`
  left: 40vw;
  text-align: center;
`;

export default function TableUsers({
  usersList,
  handleFetchAll, 
  setResults,
  ids,
  handleCheckAll,
  handleCheckboxChange,
  usersWithCheck,
  selectAll
}) {
  const { t: homeT } = useTranslation('home');
  const { t: usersT } = useTranslation('users');
  const [show, setShow] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [edit] = useState(true);
  const dispatch = useDispatch();
  const [sent, setSent] = useState();
  
  const ctx = useContext(Responsive);

  let history = useHistory ();

  const handleShow = (idx) => () => {
    setShow((state) => ({
      ...state,
      [idx]: !show[idx],
    }));
  };

  const handleShowModal = (idx) => () => {
    setShowModal((state) => ({
      ...state,
      [idx]: true,
    }));
  };

  const handleShowDelete = (idx) => () => {
    setShowModal((state) => ({
      ...state,
      [idx]: true,
    }));
    setConfirm(true);
  };

  function handleShowOff() {
    setShowModal(false);
    setShow(false);
    setSuccess(false);
    setConfirm(false);
  }

  function handleDelete(id) {
    dispatch(deleteUser(id));
  }

  const postImpersonate = async (id) => {
    let res = await network
      .post(`/api/users/${id}/become`)
      .then(({ data }) => {
        ctx.setIsPersonate(true);
        ctx.setImpersonate(data.results);
        window.localStorage.setItem('IMPERSONATE', true);
        window.localStorage.setItem('USER', JSON.stringify(data.results));
        setTimeout(() => {
          // 
          history.push('/');
          history.go(0);
        }, "1000");
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
    setResults(res.results);
  };

  const handleImpersonate = (user) => () => {
    ctx.setImpersonate(user);
    postImpersonate(user.id);
  };

  useEffect(() => {
    if(sent) {
      setTimeout(() => {
        setSent(false);
      }, 4000);
    }

    //return () => clearTimeout(timer);
  }, [sent]);

  const postResend = async (id) => {
    let res = await network
      .put(`/api/users/resend_invite/${id}`)
      .then(({ data }) => {
        setSent(data.results);
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  const handleResend = (user) => () => {
    postResend(user.id);
  };

  return (
    <>
      <TableNew>
        <tbody>
          <tr className='first'>
            <th>
              <Check className='check-all'
                type="checkbox"
                onChange={handleCheckAll}
                checked={selectAll}
              />
              <span></span>
            </th>
            <th>
              <TableTitle>{usersT('user')}</TableTitle>
            </th>
            {!ctx.isMobile && (
              <>
                <th>
                  <TableTitle>{usersT('status')}</TableTitle>
                </th>
                <th>
                  <TableTitle>{usersT('access')}</TableTitle>
                </th>

                <th>
                  <TableTitle>{usersT('availableWebsites')}</TableTitle>
                </th>
              </>
            )}
          </tr>
          {usersList &&
            usersList?.map((user, idx) => (
              <tr key={user?.id}>
                <td className='checkbox'>
                  <Check
                    type="checkbox"
                    onChange={() => handleCheckboxChange(user?.id)}
                    checked={usersWithCheck?.includes(user?.id)}
                  /> 
                  <span></span>
                </td>
                <UserCell>
                  {!ctx.isMobile && <UserIcon />}
                  <TextBlack> {user?.full_name || user?.first_name + ' ' + user?.last_name}</TextBlack>
                  {ctx.isMobile && (
                    <>
                      <Text> {user?.pretty_role}</Text>
                      {!user.status ? (
                        <Detected>{usersT('active')}</Detected>
                      ) : (
                        <Notdetected>{usersT('notActive')}</Notdetected>
                      )}
                      {user?.websites_list.map(el => (
                        <TextBlack key={el.id}>{el.name}</TextBlack>
                      ))}
                    </>
                  )}
                </UserCell>
                {!ctx.isMobile && (
                  <>
                    <td>
                      {user?.active ? (
                        <Detected>{usersT('active')}</Detected>
                      ) : (
                        <Notdetected>{usersT('notActive')}</Notdetected>
                      )}
                      <TextBlack> {}</TextBlack>
                    </td>
                    <td>
                      <TextBlack> {user?.pretty_role || user?.role}</TextBlack>
                    </td>
                  </>
                )}
                {!ctx.isMobile && user?.websites_list && (
                  <Td>
                    {user?.websites_list.length ? user.websites_list.map(el => (
                      <TextBlack key={el.id}>{el.name}</TextBlack>
                    )) : null}
                  </Td>
                )}
                <td>
                  <ManageRight onClick={handleShow(idx)}>
                    <Nav />
                    {show[idx] && (
                      <>
                        <OverManage onClick={handleShowOff}></OverManage>
                        <ManageBoxRight>
                          <a onClick={handleImpersonate(user)}>
                            <Imperson />
                            <span>{homeT('imperson')}</span>
                          </a>
                          <a onClick={handleShowModal(idx)}>
                            <Edit />
                            <span>{homeT('edit')}</span>
                          </a>
                          <a onClick={handleShowDelete(idx)}>
                            <Delete />
                            <span>{homeT('delete')}</span>
                          </a>
                          {user.can_resend_invite && (<a onClick={handleResend(user)}>
                            <Resend />
                            <span>{homeT('resend')}</span>
                          </a>
                          )}
                        </ManageBoxRight>
                      </>
                    )}
                  </ManageRight>
                </td>
                {showModal[idx] && (
                  <>
                    <Modal>
                      <Overflow onClick={handleShowOff}></Overflow>
                      {!success && !confirm && (
                        <Popup>
                          <FormUser
                            handleFetchAll={handleFetchAll}
                            edit={edit}
                            user={user}
                            ids={ids}
                            handleShowOff={handleShowOff}
                            setUsersWithCheck
                          />
                        </Popup>
                      )}
                      {success && <Success />}
                      {confirm && (
                        <Confirm>
                          <TextBlack>{usersT('delete')}</TextBlack>
                          <ButtonsWrap>
                            <SecondaryBtnHigh onClick={handleShowOff}>
                              {homeT('cancel')}
                            </SecondaryBtnHigh>
                            <FilledButton
                            /* eslint-disable */
                            onClick={() => handleDelete(user.id)}
                            /* eslint-disable */
                            >
                              {homeT('delete')}
                            </FilledButton>
                          </ButtonsWrap>
                        </Confirm>
                      )}
                    </Modal>
                  </>
                )}
              </tr>
            ))}
        </tbody>
      </TableNew>
    {sent && ( <Results>{usersT('sent')}{sent.first_name}{' '}{' '}<br></br>{sent.last_name}{' '}{sent.email}</Results>)}
    </>
  );
}

TableUsers.propTypes = {
  usersList: array,
  handleFetchAll: func,
  handleImpersonate: func,
  setResults: func,
  ids: any,
  handleCheckAll: func,
  handleCheckboxChange: func,
  usersWithCheck: array,
  selectAll: bool
};
