import React from "react";
import Error from "./Error";
import network from "base/network";
import { any, array } from "prop-types";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.requestHistory = props.requestHistory;
    this.actionHistory = props.actionHistory;
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }
  
  componentDidCatch(error, errorInfo) {

    const trimmedComponentStack = errorInfo.componentStack.split('\n').slice(0, 4).join('\n');

    const trimmedErrorInfo = {
      ...errorInfo,
      componentStack: trimmedComponentStack
    };
     
    network
      .post('/api/error_report', {
        message:error.toString(), 
        backtrace:(JSON.stringify(trimmedErrorInfo, null, 2)),
        requests: this.requestHistory.map(obj => `url: '${obj.url}', method: '${obj.method}'`).join(', '),
        actions: this.props.actionHistory.join(', '),
        page_path: window.location.pathname
      })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => console.log(error));
  }
  
  render() {

    if (this.state.hasError) {
      return <Error />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
  children: any,
  actionHistory: array,
  requestHistory: array
};