import styled from 'styled-components';

const ShadowButton = styled.a`
  font-family: inherit;
  font-weight: 400;
  padding: 7px 14px 6px 27px;
  font-size: 12px;
  text-decoration: none;
  height: 30px;
  border-radius: 15px;
  border: none;
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 1px 5px 0 rgba(87, 85, 85, 0.24);
  &.reload {
    max-width: 200px;
    margin: 0 auto;
  }
  svg {
    margin: 0 8px -4px 15px;
  }
`;

export default ShadowButton;

