import Leads from 'pages/Leads';
// import Contacts from 'pages/Contacts';
// import Emails from 'pages/Emails';
import Notifications from 'pages/Notifications';
import Company from 'pages/Company';
import Profile from 'pages/Profile';
import Conversions from 'pages/Conversions';
import Personalize from 'pages/Personalize';
import AutoAssign from 'pages/AutoAssign';
import Hidden from 'pages/Hidden';
import Websites from 'pages/Websites';
import Users from 'pages/Users';
import Receipts from 'pages/Receipts';
import Integrations from 'pages/Integrations';
import { ReactComponent as Lead } from 'assets/images/leads.svg';
import Benchmark from 'pages/Benchmark';
// import { ReactComponent as Contact } from 'assets/images/contacts.svg';
// import { ReactComponent as Email } from 'assets/images/emails.svg';
import { ReactComponent as Compan } from 'assets/images/company.svg';
import { ReactComponent as CompanyShadow } from 'assets/images/company-shadow.svg';
import { ReactComponent as Personalization } from 'assets/images/personalisation.svg';
import { ReactComponent as PersShadow } from 'assets/images/personalize-shadow.svg';
import { ReactComponent as Benchmarking } from 'assets/images/benchmark.svg';
import { ReactComponent as BenchShadow } from 'assets/images/benchmark-shadow.svg';
import { ReactComponent as Conversion } from 'assets/images/currency.svg';
import { ReactComponent as ConversionShadow } from 'assets/images/conversion-shadow.svg';
import { ReactComponent as Dash } from 'assets/images/dashboard.svg';
import { ReactComponent as Acc } from 'assets/images/acc.svg';
import { ReactComponent as LeadsShadow } from 'assets/images/leads-shadow.svg';
import { ReactComponent as Assign } from 'assets/images/assign.svg';
import { ReactComponent as Hide } from 'assets/images/hidden.svg';
import { ReactComponent as User } from 'assets/images/user-circle.svg';
import { ReactComponent as Website } from 'assets/images/websites.svg';
import { ReactComponent as Receipt } from 'assets/images/receipts.svg';
import { ReactComponent as IntegrationImg } from 'assets/images/integrations.svg';
import { ReactComponent as Notification } from 'assets/images/notificate.svg';
import Denied from 'base/components/Leads/Denied';

const routes = {
  main: [
    {
      name: 'profile',
      path: '/profile',
      exact: true,
      main: Profile, 
      user: true,
      manager: Profile
    }
  ],
  sideNav: [
    {
      name: 'leads',
      path: '/', 
      access: '/',
      isPersonalization: false,
      strict: true,
      exact: true,
      main: Leads,
      icon: <Lead />,
      iconActive: <LeadsShadow />,
      className: 'leadsItem',
      leadsNav: true,
      user: true,
      manager: Leads
    },
    {
      name: 'personalize',
      path: '/personalize',
      access: '#',
      isPersonalization: true,
      exact: true,
      main: Personalize,
      icon: <Personalization />,
      iconActive: <PersShadow />,
      manager: Denied
    },
    {
      name: 'conversions',
      path: '/conversions',
      access: '/conversions',
      isPersonalization: false,
      exact: true,
      main: Conversions,
      icon: <Conversion />,
      iconActive: <ConversionShadow />,
      manager: Denied
    },
    {
      name: 'benchmark',
      path: '/benchmark',
      access: '/benchmark',
      isPersonalization: false,
      exact: true,
      main: Benchmark,
      icon: <Benchmarking />,
      iconActive: <BenchShadow />,
      manager: Denied
    },
    {
      name: 'company',
      path: '/company',
      access: '/company',
      isPersonalization: false,
      exact: true,
      main: Company,
      icon: <Compan />,
      iconActive: <CompanyShadow />,
      className: 'companyItem beta',
      subNav: true,
      manager: Denied
    },
  ],
  leadNav: [
    {
      name: 'dashboard',
      path: '/dashboard',
      exact: true,
      main: Leads,
      icon: <Dash />,
      manager: Denied
    },
    {
      name: 'autoAssign',
      path: '/auto-assign',
      exact: true,
      main: AutoAssign,
      icon: <Assign />,
      manager: Denied
    },
    {
      name: 'hidden',
      path: '/hidden',
      exact: true,
      main: Hidden,
      icon: <Hide />,
      manager: Denied
    },
    {
      name: 'notifications',
      path: '/notifications',
      exact: true,
      main: Notifications,
      icon: <Notification />,
      user: true,
      manager: Notifications
    }
  ],
  companyNav: [
    {
      name: 'account',
      path: '/account',
      exact: true,
      main: Company,
      icon: <Acc />,
      manager: Denied
    },
    {
      name: 'users',
      path: '/users',
      exact: true,
      main: Users,
      icon: <User />,
      manager: Denied
    },
    {
      name: 'receipts',
      path: '/receipts',
      exact: true,
      main: Receipts,
      icon: <Receipt />,
      manager: Denied
    },
    {
      name: 'websites',
      path: '/websites',
      exact: true,
      main: Websites,
      icon: <Website />,
      manager: Denied
    },
    {
      name: 'integrations',
      path: '/integrations',
      exact: true,
      main: Integrations,
      icon: <IntegrationImg />,
      manager: Denied,
      className: "integrations"
    }
  ]
};

export const selectRouteByKey = (key) => {
  
  if( routes[key] ) {
    return routes[key].map( item => item.path);
  }
  return [];
};

export default routes;
