import { useState, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePersonalization,
  fetchAllPersonalizations,
} from "store/slices/personalizations";
import { useTranslation } from "react-i18next";
import { makeAsOptions } from "base/utils";
import {
  AccentButton,
  Block,
  Page,
  TextBlack,
  Manage,
  ManageBox,
  TextBlackThin,
  TextGreyBold,
  TextWhite,
  TitleBlack,
  TitleTwo,
  Overlay,
  Loader,
  OutlinedButton,
  Modal,
  Confirm,
  Overflow,
  FilledButton,
  FlexEnd,
  SearchForm,
  StyledSelect,
  Loading,
  TextLight
} from "base/styled";
import { ReactComponent as Running } from "assets/images/running.svg";
import { ReactComponent as Paused } from "assets/images/paused.svg";
import { ReactComponent as Spinner } from "assets/images/spin.svg";
import { ReactComponent as Plus } from "assets/images/plus_white.svg";
import { ReactComponent as Nav } from "assets/images/manage_v.svg";
import { ReactComponent as Delete } from "assets/images/delete_dark.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { ReactComponent as SearchIcon } from "assets/images/search.svg";
import { ReactComponent as Empty } from "assets/images/empty_personalization.svg";
import styled from "styled-components";
import NewForm from "./NewForm";
import Responsive from "context/responsive";
import Success from "base/components/Success";
import network from "base/network";
import { fetchAllConversions } from "store/slices/conversions";
import { Calendar } from "base/components/Leads";
import TooltipDark from "base/components/TooltipDark";

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    button {
      height: 45px;
    }
  }
`;

const Subheader = styled(TextBlackThin)`
  margin-bottom: 40px;
`;

const ButtonCenter = styled(AccentButton)`
  width: 60%;
  margin: 0 auto;
`;

const SearchBox = styled.div`
  padding: 20px 0 20px;
  display: flex;
  justify-content: space-between;
  form {
    min-width: 200px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Title = styled(TitleBlack)`
  text-align: left;
`;

const Box = styled(Block)`
  position: relative;
  padding: 14px 25px 10px;
  align-items: center;
  position: static;
  border-radius: 10px;
  overflow: hidden;
  transition: .3s;
  &:hover {
    background: #FFF9F0;
  }
  hr {
    content: '';
    position: absolute;
    display: block;
    width: 88%;
    margin-top: -126px;
    border: none;
    background: #ECEBEB;
    height: 1px;
    margin-left: -12px;
  }
  @media (min-width: 769px) {
    height: 130px;
    width: 98%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    margin: 5px 0 5px 0;
    flex-direction: column;
    align-items: initial;
    padding: 0;
    margin: 20px 0;
    .block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 15px;
      text-align: left;
    } 
    .select-block {
      padding:15px;
    }
    p:nth-child(2) {
      margin-right: 40px;
    }
    p.item-name {
      margin-right: 0;
      color: #85929B;
    }
    .coloured {
      background: #F9F9F9;
    }
    .first {
      border-radius: 15px 15px 0 0;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 95%;
        height: 1px;
        left: 10px;
        bottom: 0;
        background: #ECEBEB;
      }
    }
  }
`;

const BoxHead = styled.div`
  padding: 10px 22px;
  display: flex;
  background: #fff;
  border-radius: 15px 15px 0 0;
`;

const Name = styled.div`
  width: 22%;
  cursor: pointer;
  position: relative;
  .name {
    margin: 0 0 40px 0;
  }
  .title {
    margin: 0 0 35px 18px;
     @media (max-width: 768px) {
      margin: 0;
      padding-left: 15px;
      display: flex;
     }
  }
  .full-name {
    max-width: 100px;
    overflow-x: scroll;
  }
  position: relative;
`;

const TextLightBold = styled.div`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0rem;
  color: ${(props) => props.theme.colors.lightGrey};
`;

const Button = styled.div`
  border-radius: 38px;
  background: linear-gradient(111deg, #34BC52 7.95%, #1E8F37 102.41%);
  padding: 8px 20px 8px;
  cursor: pointer;
  text-align: center;
  width: 95px;
  margin-left: auto;
  p {
    margin: 0;
  }
  .spinner {
    animation: spin-animation 0.7s infinite;
    display: inline-block;
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  @media (max-width: 768px) {
    display: flex;
    padding: 5px 8px;
    margin: 5px 0;
    width: auto;
    p:nth-child(2) {
      margin: 5px;
    }
    svg {
      margin-top: 6px;
    }
  }
  
`;

const Campaign = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  background: ${(props) => props.theme.colors.bgMain};
  padding: 40px 0 0 100px;
  margin-left: -43px;
  z-index: 5;
  .url {
    color: #73ccfe;
    font-size: 0.875rem;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
  }
  button {
    margin-top: 35px;
  }
  @media (max-width: 768px) {
    padding: 70px 0 0 18px;
    margin-left: 0;
  }
  &.edit {
    position: absolute;
    top: 0;
    height: 100%;
  }
`;

const ManageBoxRight = styled(ManageBox)`
  right: -5px;
  top: 27px;
`;

const ManageWrap = styled(Manage)`
  padding: 0 0 15px 0;
  svg {
    margin-top: 4px;
  }
`;

const Over = styled(Overlay)`
  z-index: 0;
  background: #f9f9f9;
`;

const OverManage = styled(Overlay)`
  z-index: 19;
  background: inherit;
`;

const Right = styled.div`
  margin-right: auto;
`;

const Margin = styled.div`
  margin: 0 auto;
  text-align: center;
  .empty {
    margin: 60px 0 40px;
  }
  button {
    margin-top: 20px;
  }
`;

const Visitors = styled.div`
  width: 10%;
`;

const Time = styled.div`
  width: 14%;
`;

const Pages = styled.div`
  width: 20%;
`;

const Bounce = styled.div`
  width: 10%;
`;

const Status = styled.div`
@media (max-width: 768px) {
  margin: 0 auto;
}
`;

const Conversions = styled.div`
  width: 10%;
  &.value {
    padding-left: 10px;
    @media (max-width: 768px) {
      padding-left: 0px;
      margin-right: 25px;
    }
  }
`;

const Control = styled.div`
  width: 14%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    a {
      width: 50px;
      display: block;
    }
  }
`;

const Select = styled(StyledSelect)`
  min-width: auto;
  width: 170px;
  margin-left: 6px;
  .css-tlfecz-indicatorContainer {
    padding: 8px 5px 8px 0;
  }
  @media (max-width: 768px) {
    min-width: 180px;
    width: auto;
  }
`;

const Wrap = styled.div`
display: flex;
flex-direction: column;
background: #fff
`;

const Created = styled.div`
 max-width: 120px;
 overflow-x: scroll;
 @media (max-width: 768px) {
  max-width: fit-content;
  text-align: left;
  width:56%;
 }
`;

const Load = styled(Loading)`
  height: 40vh;
`;

const Tooltip = styled(TooltipDark)`
 top: 0;
 left: -3px;
 position: absolute;
  @media (max-width: 768px) {
    top: 27px;
    left: 8px;
  }
 
`;

export default function Index() {
  const { t: rootT } = useTranslation("");
  const { t: personalizationT } = useTranslation("personalization");
  const dispatch = useDispatch();
  const [campaign, setCampaign] = useState();
  const [showCampaign, setShowCampaign] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [manage, setManage] = useState(false);
  const [stat, setStat] = useState();
  const [url, setUrl] = useState();
  const [confirm, setConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [edit, setEdit] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [text, setText] = useState("");
  const [statusRun, setStatusRun] = useState(false);
  const [res, setRes] = useState(false);
  const [gic, setGic] = useState([]);
  const [naics, setNaics] = useState([]);
  const [editted, setEditted] = useState(false);
  const [startDay, setStartDay] = useState(
    new Date(Date.now() - 1000 * 60 * 60 * 24 * 7).toISOString().slice(0, 10)
  );
  const [endDay, setEndDay] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [changeDate, setChangeDate] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { conversions, statusConversion } = useSelector((state) => state.conversionsReducer);
  const options1 = makeAsOptions(conversions, "id", "name");
  const options = [
    { value: '', label: 'Select conversion', isDisabled: true },
    ...options1
  ];
  const [changeStat, setChangeStat] = useState(false);
  const gaViews = useSelector((state) => state.gaViewIdReducer);
  const {
    personalizations,
    status,
    personalization,
    statusNew,
    summary,
    id
  } = useSelector((state) => state.personalizationsReducer);
  const ctx = useContext(Responsive);
 
  const getUrl = async (id) => {
    setStat("loading");
    let res = await network
      .get(`/api/personalization_campaigns/${id}/set_editor_uuid`)
      .then(({ data }) => data)
      .catch((error) => console.log(error));
    setUrl(res.results);
  };

  const [summ, setSumm] = useState([]);
  const [notification, setNotification] = useState(false);

  useEffect(() => {
    if(statusNew){
      setNotification(true);
    }
  }, [statusNew]);

  // useEffect(() => {
  //   if (notification) {
  //     const timer = setTimeout(() => {
  //       setNotification(false);
  //     }, 3000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [notification]);

  /* eslint-disable */
  useEffect(() => {
    if (url) {
      window.open(url, "_blank");
      setStat("succeed");
      setUrl();
    }
  });

  useEffect(() => {
    dispatch(fetchAllConversions({ conversions }));
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (
      (gaViews &&
        gaViews?.ids.length > 0 &&
        gaViews?.ids.length != summ.length) ||
      (gaViews && gaViews?.ids.length > 0 && changeDate)
    ) {
      setSumm([]);
      gaViews?.ids.forEach((e) => {
        network
          .get("api/personalization_campaigns/statistic_summary", {
            params: {
              google_analytics_view_id: e.id,
              min_date: startDay,
              max_date: endDay,
              page: 1,
              per_page: 100,
            },
          })
          .then(({ data }) => {
            const newObject = { id: e.id, name: e.name, ...data.results };
            setSumm((prevArray) => {
              const newArray = [...prevArray];
              newArray.push(newObject);
              return newArray;
            });
          })
          .catch((error) => console.log(error));
      });
    }
    /* eslint-disable */
  }, [gaViews.ids, summary, startDay, endDay]);
  /* eslint-enable */

  useEffect(() => {
    dispatch(
      fetchAllPersonalizations({
        conversion_campaign_id: selectedId?.value,
        min_date: startDay,
        max_date: endDay,
        per_page: 100,
      })
    );

    if (searchResults == 0) {
      setSearchResults(personalizations);
    }
    if(status=='succeed' && changeStat) {
      setChangeStat(false);
      setStatusRun(false);
    } 
    /* eslint-disable */
  }, [dispatch, editted, selectedId, startDay, endDay]);
  /* eslint-enable */

  useEffect(() => {
    setSearchResults(personalizations);
    if(status=='succeed') {
      setStatusRun(false);
    } 
    /* eslint-disable */
  }, [status]);
  /* eslint-enable */

  const handleShowCampaign = (idx) => () => {
    const campaign = personalizations.find((el) => el.id === idx);
    setCampaign(campaign);
    window.scrollTo(0, 0);
    if (showCampaign) {
      setCampaign(campaign);
      return;
    } else {
      setShowCampaign(true);
    }
  };

  function handleCloseCampaign() {
    setShowCampaign(false);
  }

  const handleStatus = (id, stat, idx) => () => {
    setStatusRun((state) => ({
      ...state,
      [idx]: true,
    }));
    setChangeStat(true);
    
    network
      .put(`/api/personalization_campaigns/${id}/set_status`, {
        status: stat === "started" ? false : true,
      })
      .then(({ data }) => {
        setRes((state) => ({
          ...state,
          [idx]: data.results.status,
        }));
        // setRes(data.results.status);
     
        dispatch(
          fetchAllPersonalizations({
            conversion_campaign_id: selectedId?.value,
            min_date: startDay,
            max_date: endDay,
            per_page: 100,
          })
        );

    
        return data;
      })
      .catch((error) => console.log(error));
  };

  function handleShowForm() {
    setShowForm(true);
  }

  function handleShowFormOff() {
    setShowForm(false);
    setEdit(false);
  }

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  const handleUpdate = (idx) => () => {
    setEdit((state) => ({
      ...state,
      [idx]: true,
    }));
    executeScroll();
  };

  const handleManage = (idx) => () => {
    setManage((state) => ({
      ...state,
      [idx]: !manage[idx],
    }));
  };

  const handleShowDelete = (idx) => () => {
    setShowModal((state) => ({
      ...state,
      [idx]: true,
    }));
    setConfirm(true);
  };

  function handleDelete(id) {
    dispatch(deletePersonalization(id));
    handleShowOff();
  }

  function convertTime(str) {
    if (str == '0.0') {
      return str;
    } else {
      var totalSeconds = parseFloat(str);
      var minutes = Math.floor(totalSeconds / 60);
      var seconds = Math.floor(totalSeconds % 60);
    
      var result = minutes + "m " + seconds + "sec";
      return result;
    }
  
  }

  function renderPersonalizations(item, idx) {
    return (
      <Wrap>
        {ctx.isMobile ? (
          <Box key={item.id}>
            <Name className='block first'>
              <TextGreyBold className="item-name"> {item.name}</TextGreyBold>
              <Created>
                <TextBlackThin>{personalizationT("assignTo")}</TextBlackThin>
                <TextLightBold
                  onClick={handleShowCampaign(item?.id)}
                >
                 
                </TextLightBold>
              </Created>  
              <TextBlack className="full-name">{item?.user?.full_name}</TextBlack>
            </Name>
            <Visitors className='block'>
              <TextGreyBold>{personalizationT("visitors")}</TextGreyBold>
              <TextBlackThin>{item?.stats?.visitors}</TextBlackThin>
            </Visitors>
            <Pages className='block'>
              <TextGreyBold>{personalizationT("pagesViewed")}</TextGreyBold>
              <TextBlackThin>{item?.stats?.average_page_views}</TextBlackThin>
            </Pages>
            <Time className='block'>
              <TextGreyBold>{personalizationT("timeSpent")}</TextGreyBold>
              <TextBlackThin>{convertTime(item?.stats?.average_time_spent)}</TextBlackThin>
            </Time>
            <Bounce className='block'>
              <TextGreyBold>{personalizationT("bounce")}</TextGreyBold>
              <TextBlackThin>{+item?.stats?.bounce_rate * 100  != 0 ?
                (+item?.stats?.bounce_rate * 100 + '%') : item?.stats?.bounce_rate}</TextBlackThin>
            </Bounce>
            
            <div className='block select-block'>
              <Select
                options={options}
                components={{ IndicatorSeparator: () => null }}
                placeholder={"Select conversion"}
                styles={style}
                value={selectedId}
                onChange={handleSelectChange}
              />
              <Conversions className="value">
                {selectedId
                  ? conversions.find((e) => e.id === selectedId.value)
                    ?.completions_count
                  : "--"}
              </Conversions>
             
            </div>
            
            <Status>
              <Button onClick={handleStatus(item.id, item.status, idx)}>
                {item.status === "started" ? (
                  <>
                    {/* {run[idx] ? <Running /> : <Paused />} */}
                    {statusRun[idx] ? (
                      <Spinner className="spinner" />
                    ) : res[idx] ? (
                      res[idx] === "started" ? (
                        <Running />
                      ) : (
                        <Paused />
                      )
                    ) : (
                      <Running />
                    )}
                    <TextWhite>
                      {res[idx]
                        ? res[idx] === "started"
                          ? personalizationT("running")
                          : personalizationT("paused")
                        : personalizationT("running")}
                    </TextWhite>
                  </>
                ) : (
                  <>
                    {statusRun[idx] ? (
                      <Spinner className="spinner" />
                    ) : res[idx] ? (
                      res[idx] === "started" ? (
                        <Running />
                      ) : (
                        <Paused />
                      )
                    ) : (
                      <Paused />
                    )}
                    <TextWhite>
                      {res[idx]
                        ? res[idx] === "started"
                          ? personalizationT("running")
                          : personalizationT("paused")
                        : personalizationT("paused")}
                    </TextWhite>
                  </>
                )}
              </Button>
            </ Status>
            <Status>
              <Control>
                <a onClick={handleUpdate(idx)}>
                  <Edit />
                </a>
                <a onClick={handleShowDelete(idx)}>
                  <Delete />
                </a>
              </Control>
            </Status>
           
            {showModal[idx] && (
              <>
                <Modal>
                  <Overflow onClick={handleShowOff}></Overflow>
                  {success && <Success />}
                  {confirm && (
                    <Confirm>
                      <TextBlack>{personalizationT("delete")}</TextBlack>
                      <FlexEnd>
                        <OutlinedButton onClick={handleShowOff}>
                          {rootT("cancel")}
                        </OutlinedButton>
                        <FilledButton
                          /* eslint-disable */
                          onClick={() => handleDelete(item.id)}
                          /* eslint-enable */
                        >
                          {rootT("delete")}
                        </FilledButton>
                      </FlexEnd>
                    </Confirm>
                  )}
                </Modal>
              </>
            )}
            {edit[idx] && (
              <>
                <Over></Over>
                <Campaign className="edit">
                  {ctx.isMobile ? (
                    <TitleTwo>{personalizationT("edit")}</TitleTwo>
                  ) : (
                    <Title>{personalizationT("edit")}</Title>
                  )}
                  <NewForm
                    handleShowFormOff={handleShowFormOff}
                    edit={true}
                    editItem={item}
                    gic={gic}
                    naics={naics}
                    setEditted={setEditted}
                  />
                </Campaign>
              </>
            )}
          </Box>
        ) : (
          <Box key={item.id}>
            <Name>
              <TextLightBold
                className="name"
                onClick={handleShowCampaign(item?.id)}
              >
                {item.name}
              </TextLightBold>
              <TextBlackThin>{personalizationT("assignTo")}</TextBlackThin>
              <TextBlack>{item?.user?.full_name}</TextBlack>
            </Name>
            <Visitors>
              <TextBlackThin>{item?.stats?.visitors}</TextBlackThin>
            </Visitors>
            <Pages>
              <TextBlackThin>{item?.stats?.average_page_views}</TextBlackThin>
            </Pages>
            <Time> 
              <TextBlackThin>{convertTime(item?.stats?.average_time_spent)}</TextBlackThin>
            </Time>
            <Bounce>
              <TextBlackThin>{+item?.stats?.bounce_rate * 100  != 0 ?
                (+item?.stats?.bounce_rate * 100 + '%') : item?.stats?.bounce_rate}</TextBlackThin>
            </Bounce>
            <Conversions className="value">
              {selectedId
                ? conversions.find((e) => e.id === selectedId.value)
                  ?.completions_count
                : "--"}
            </Conversions>
            <Control>
              <ManageWrap onClick={handleManage(idx)}>
                <Nav />
                {manage[idx] && (
                  <>
                    <OverManage onClick={handleShowOff}></OverManage>
                    <ManageBoxRight>
                      <a onClick={handleUpdate(idx)}>
                        <Edit />
                        <span>{rootT("edit")}</span>
                      </a>
                      <a onClick={handleShowDelete(idx)}>
                        <Delete />
                        <span>{rootT("delete")}</span>
                      </a>
                    </ManageBoxRight>
                  </>
                )}
              </ManageWrap>
              <Button onClick={handleStatus(item.id, item.status, idx)}>
                {item.status === "started" ? (
                  <>  
                    {/* {run[idx] ? <Running /> : <Paused />} */}
                    {statusRun[idx] ? (
                      <>
                        <Spinner className="spinner" />
                      </>
                     
                    ) : res[idx] ? (
                      res[idx] === "started" ? (
                        <Running />
                      ) : (
                        <Paused />
                      )
                    ) : (
                      <Running />
                    )}
                    <TextWhite>
                      {res[idx]
                        ? res[idx] === "started"
                          ? personalizationT("running")
                          : personalizationT("paused")
                        : personalizationT("running")}
                    </TextWhite>
                  </>
                ) : (
                  <>
                    {statusRun[idx] ? (
                      <Spinner className="spinner" />
                    ) : res[idx] ? (
                      res[idx] === "started" ? (
                        <Running />
                      ) : (
                        <Paused />
                      )
                    ) : (
                      <Paused />
                    )}
                    <TextWhite>
                      {res[idx]
                        ? res[idx] === "started"
                          ? personalizationT("running")
                          : personalizationT("paused")
                        : personalizationT("paused")}
                    </TextWhite>
                  </>
                )}
              </Button>
            </Control>

            {showModal[idx] && (
              <>
                <Modal>
                  <Overflow onClick={handleShowOff}></Overflow>
                  {success && <Success />}
                  {confirm && (
                    <Confirm>
                      <TextBlack>{personalizationT("delete")}</TextBlack>
                      <FlexEnd>
                        <OutlinedButton onClick={handleShowOff}>
                          {rootT("cancel")}
                        </OutlinedButton>
                        <FilledButton
                          /* eslint-disable */
                          onClick={() => handleDelete(item.id)}
                          /* eslint-enable */
                        >
                          {rootT("delete")}
                        </FilledButton>
                      </FlexEnd>
                    </Confirm>
                  )}
                </Modal>
              </>
            )}
            {edit[idx] && (
              <>
                <Over></Over>
                <Campaign className="edit">
                  {ctx.isMobile ? (
                    <TitleTwo>{personalizationT("edit")}</TitleTwo>
                  ) : (
                    <Title>{personalizationT("edit")}</Title>
                  )}
                  <NewForm
                    handleShowFormOff={handleShowFormOff}
                    edit={true}
                    editItem={item}
                    gic={gic}
                    naics={naics}
                    setEditted={setEditted}
                  />
                </Campaign>
              </>
            )}
            <hr />
          </Box>
        )}
      </Wrap>
    );
  }

  function renderStats(item, idx) {
    return (
      <Wrap key={idx}>
        {ctx.isMobile ? (
          <>
            <Box>
              <Name className='block first'>
                <Tooltip text={personalizationT("tooltipSummaryAll")} />
                <TextGreyBold  className="title item-name"
                  onClick={handleShowCampaign(item?.id)}> 
                  All traffic </TextGreyBold>
                <Created>
                  <TextLight>{personalizationT("assignTo")}</TextLight>
                  <TextBlack>{item.name}</TextBlack>
                </Created>
              </Name>
              <Visitors className='block'>
                <TextGreyBold>{personalizationT("visitors")}</TextGreyBold>
                <TextBlackThin>{item?.all_traffic?.visitors}</TextBlackThin>
              </Visitors>
              <Pages className='block '>
                <TextGreyBold>{personalizationT("pagesViewed")}</TextGreyBold>
                <TextBlackThin>
                  {item?.all_traffic?.average_page_views}
                </TextBlackThin>
              </Pages>
              <Time className='block'>
                <TextGreyBold>{personalizationT("timeSpent")}</TextGreyBold>
                <TextBlackThin>
                  {convertTime(item?.all_traffic?.average_time_spent)}
                </TextBlackThin>
              </Time>
              <Bounce className='block '>
                <TextGreyBold>{personalizationT("bounce")}</TextGreyBold>
                <TextBlackThin>{ +item?.all_traffic?.bounce_rate * 100  != 0 ?
                  (+item?.all_traffic?.bounce_rate * 100 + '%') : item?.all_traffic?.bounce_rate}</TextBlackThin>
              </Bounce>
              <div className='block select-block'>
                <Select
                  options={options}
                  components={{ IndicatorSeparator: () => null }}
                  placeholder={"Select conversion"}
                  styles={style}
                  value={selectedId}
                  onChange={handleSelectChange}
                />
                <Conversions className="value">
                  {selectedId
                    ? conversions.find((e) => e.id === selectedId.value)
                      ?.completions_count
                    : "--"}
                </Conversions>
              </div>
             
            </Box>
            <Box>
              <Name className='block first'>
                <Tooltip text={personalizationT("tooltipSummary")} />
                <TextGreyBold className="title item-name"
                  onClick={handleShowCampaign(item?.id)}>  All Un-<br/>personalized <br/>Traffic  
                </TextGreyBold>
                
                <Created>
                  <TextLight>{personalizationT("assignTo")}</TextLight>
                  <TextBlack>{item.name}</TextBlack>
                </Created>
              </Name>
              <Visitors className='block'>
                <TextGreyBold>{personalizationT("visitors")}</TextGreyBold>
                <TextBlackThin>
                  {item?.un_personalize_traffic?.visitors}
                </TextBlackThin>
              </Visitors>
              <Pages className='block'>
                <TextGreyBold>{personalizationT("pagesViewed")}</TextGreyBold>
                <TextBlackThin>
                  {item?.un_personalize_traffic?.average_page_views}
                </TextBlackThin>
              </Pages>
              <Time className='block'>
                <TextGreyBold>{personalizationT("timeSpent")}</TextGreyBold>
                <TextBlackThin>
                  {convertTime(item?.un_personalize_traffic?.average_time_spent)}
                </TextBlackThin>
              </Time>
              <Bounce className='block'>
                <TextGreyBold>{personalizationT("bounce")}</TextGreyBold>
                <TextBlackThin>
                  { +item?.all_traffic?.bounce_rate * 100  != 0 ?
                    (+item?.all_traffic?.bounce_rate * 100 + '%') : item?.all_traffic?.bounce_rate}
                </TextBlackThin>
              </Bounce>
              <div className='block select-block'>
                <Select
                  options={options}
                  components={{ IndicatorSeparator: () => null }}
                  placeholder={"Select conversion"}
                  styles={style}
                  value={selectedId}
                  onChange={handleSelectChange}
                />
                <Conversions className="value">
                  {selectedId
                    ? conversions.find((e) => e.id === selectedId.value)
                      ?.completions_count
                    : "--"}
                </Conversions>
              
              </div>
            
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Name>
                <TextLightBold
                  className="title"
                  onClick={handleShowCampaign(item?.id)}
                >
                  <Tooltip text={personalizationT("tooltipSummaryAll")} />
                  All traffic
                </TextLightBold>
                <TextBlackThin>{personalizationT("assignTo")}</TextBlackThin>
                <TextBlack>{item.name}</TextBlack>
              </Name>
              <Visitors>
                <TextBlackThin>{item?.all_traffic?.visitors}</TextBlackThin>
              </Visitors>
              <Pages>
                <TextBlackThin>
                  {item?.all_traffic?.average_page_views}
                </TextBlackThin>
              </Pages>
              <Time>
                <TextBlackThin>
                  {convertTime(item?.all_traffic?.average_time_spent)}
                </TextBlackThin>
              </Time>
              <Bounce>
                <TextBlackThin>{ +item?.all_traffic?.bounce_rate * 100  != 0 ?
                  (+item?.all_traffic?.bounce_rate * 100 + '%') : item?.all_traffic?.bounce_rate}</TextBlackThin>
              </Bounce>
              <Conversions className="value">
                {selectedId
                  ? conversions.find((e) => e.id === selectedId.value)
                    ?.completions_count
                  : "--"}
              </Conversions>
              <Control></Control>
              <hr />
            </Box>
            <Box>
              <Name>
                <TextLightBold
                  className="title"
                  onClick={handleShowCampaign(item?.id)}
                >
                  <Tooltip text={personalizationT("tooltipSummary")} />
                  All Un-personalized Traffic
                </TextLightBold>
                <TextBlackThin>{personalizationT("assignTo")}</TextBlackThin>
                <TextBlack>{item.name}</TextBlack>
              </Name>
              <Visitors>
                <TextBlackThin>
                  {item?.un_personalize_traffic?.visitors}
                </TextBlackThin>
              </Visitors>
              <Pages>
                <TextBlackThin>
                  {item?.un_personalize_traffic?.average_page_views}
                </TextBlackThin>
              </Pages>
              <Time>
                <TextBlackThin>
                  {convertTime(item?.un_personalize_traffic?.average_time_spent)}
                </TextBlackThin>
              </Time>
              <Bounce>
                <TextBlackThin>
                  { +item?.all_traffic?.bounce_rate * 100  != 0 ?
                    (+item?.all_traffic?.bounce_rate * 100 + '%') : item?.all_traffic?.bounce_rate}
                </TextBlackThin>
              </Bounce>
              <Conversions className="value">
                {selectedId
                  ? conversions.find((e) => e.id === selectedId.value)
                    ?.completions_count
                  : "--"}
              </Conversions>
              <Control></Control>
              <hr />
            </Box>
          </>
        )}
      </Wrap>
    );
  }

  function handleShowOff() {
    setShowModal(false);
    setSuccess(false);
    setConfirm(false);
    setEdit(false);
  }

  useEffect(() => {
    setSearchResults(personalizations);
    /* eslint-disable */
  }, [search]);
  /* eslint-enable */
  // this shows screen with new posted campaign
  // useEffect(() => {
  //   if (statusNew === "succeed") {
  //     setCampaign(personalization);
  //     setShowCampaign(true);
  //     window.scrollTo(0, 0);
  //   }
  // }, [personalization]);


  function handleText(e) {
    setText(e.target.value);
    setSearchResults(
      personalizations.filter((el) => {
        return el.name.toLowerCase().includes(text.toLowerCase());
      })
    );
  }

  function handleSubmit() {
    if (text) {
      setSearchResults(
        personalizations.filter((el) => {
          return el.name.toLowerCase().includes(text?.toLowerCase());
        })
      );
    } else {
      setSearchResults(personalizations);
    }
    setSearch(true);
  }

  const getIndGic = async () => {
    let res = await network
      .get("/api/industries/gic", { params: {} })
      .then(({ data }) => data)
      .catch((error) => console.log(error));

    setGic(
      res.results.map((d) => {
        return {
          value: d[0],
          label: d[1],
        };
      })
    );
  };

  useEffect(() => {
    const getInd = async () => {
      let res = await network
        .get("/api/industries/naics", { params: {} })
        .then(({ data }) => data)
        .catch((error) => console.log(error));
      setNaics(
        res.results.map((d) => {
          return {
            label: d.value,
            value: d.code,
            options: d.subindustries.map((e) => {
              return {
                label: Object.values(e).toString(),
                value: Object.keys(e).toString(),
              };
            }),
          };
        })
      );
    };
    getInd();
    getIndGic();
  }, []);

  const style = {
    option: (provided, state) => ({
      ...provided,
      color: "#000000",
      fontSize: "14px",
      background: "inherit",
      fontWeight: state.isDisabled ? '500' : '400',
      paddingBottom: state.isDisabled ? '20px' : '',
      borderBottom: state.isSelected ? "1px solid #EDEDED" : "0",
      "&:hover": {
        background: `#FFF9F9`,
      },
      padding: "10px 5px 10px 11px",
      width: ctx.isMobile ? 100 : 180,
    }),
    control: () => ({
      display: "flex",
      borderRadius: "8px",
      background: "#FFFFFF",
      //width: ctx.isMobile ? 180 : 300,
      boxShadow: '0px 1px 5px rgba(87, 85, 85, 0.24);',
      color: "#000",
      marginTop: "4px",
      "@media only screen and (min-width: 1300px)": {
        width: 180,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      width: ctx.isMobile ? 170 : 180,
      color: state.selectProps.menuColor,
      padding: 0,
      overflowX: "hidden",
      display: "inline-block",
      "@media only screen and (min-width: 1200px)": {
        width: 180,
      },
    }),
  };

  function handleSelectChange(payload) {
    setSelectedId(payload);
  }

  function handleOffNotification() {
    setNotification(false);
  }

  return (
    <Page>
      {statusConversion === "loading" ? (
        <Loader />
      ) : (
        <>
          <Flex className={ctx.isMobile ? "text-left" :''}>
            <TitleBlack>{personalizationT("title")}</TitleBlack>
         
          </Flex>
          <Subheader ref={myRef}>{personalizationT("subtitle")}</Subheader>
          {ctx.isMobile && (
            <ButtonCenter onClick={handleShowForm} >
              <Plus />
              {personalizationT("new")}
            </ButtonCenter>
          )}
          {stat === "failure" && <TextBlack>please, reload a page</TextBlack>}
          {searchResults && searchResults.length > 0 ? (
            <>
              <SearchBox>
                <SearchForm>
                  <SearchIcon onClick={handleSubmit} />
                  <input
                    type="search"
                    className="light"
                    onChange={handleText}
                  />
                </SearchForm>
                <Right>
                  <Calendar
                    setStartDay={setStartDay}
                    setEndDay={setEndDay}
                    setChangeDate={setChangeDate}
                    // changeDate={changeDate}
                  />
                </Right>
                {searchResults && !ctx.isMobile && (
                  <AccentButton onClick={handleShowForm}>
                    <Plus />
                    {personalizationT("new")}
                  </AccentButton>
                )}
              </SearchBox>
             
              {!ctx.isMobile && (
                <BoxHead>
                  <Name>
                    <TextGreyBold>{personalizationT("general")}</TextGreyBold>
                  </Name>
                  <Visitors>
                    <TextGreyBold>{personalizationT("visitors")}</TextGreyBold>
                  </Visitors>
                  <Pages>
                    <TextGreyBold>{personalizationT("pagesViewed")}</TextGreyBold>
                  </Pages>
                  <Time>
                    <TextGreyBold>{personalizationT("timeSpent")}</TextGreyBold>
                  </Time>
                  <Bounce>
                    <TextGreyBold>{personalizationT("bounce")}</TextGreyBold>
                  </Bounce>
                  <Select
                    options={options}
                    components={{ IndicatorSeparator: () => null }}
                    placeholder={"Select conversion"}
                    styles={style}
                    value={selectedId}
                    onChange={handleSelectChange}
                  />
                </BoxHead>
              )}
              {selectedId && status == "loading" && !changeStat ? (
                <Load />
              ) : (
                <>
                  {summ.length > 0 && summ?.map(renderStats)}
                </>
              )}
              {searchResults.map(renderPersonalizations)}
            </>
          ) : (
            <Margin>
              <Empty className="empty" />
              <TextBlackThin>{personalizationT("notFound")}</TextBlackThin>
              <AccentButton onClick={handleShowForm}>
                <Plus />
                {personalizationT("new")}
              </AccentButton>
            </Margin>
          )}
          {showCampaign && (
            <>
              <Over></Over>
              <Campaign>
                {ctx.isMobile ? (
                  <TitleTwo>{campaign.name}</TitleTwo>
                ) : (
                  <Title>{campaign.name}</Title>
                )}
                <>
                  <TextBlack>{personalizationT("url")}:</TextBlack>
                  <p
                    className="url"
                    /* eslint-disable */
                    onClick={() => {
                      getUrl(campaign.id);
                    }}
                    /* eslint-enable */
                  >
                    {personalizationT("pageEditor")}
                  </p>
                  <div>
                    <OutlinedButton onClick={handleCloseCampaign}>
                      {personalizationT("back")}
                    </OutlinedButton>
                  </div>
                </>
              </Campaign>
            </>
          )}
          {showForm && (
            <>
              <Over></Over>
              <Campaign>
                {ctx.isMobile ? (
                  <TitleTwo>{personalizationT("new")}</TitleTwo>
                ) : (
                  <Title>{personalizationT("new")}</Title>
                )}
                <NewForm
                  handleShowFormOff={handleShowFormOff}
                  edit={edit}
                  setCampaign={setCampaign}
                  setShowCampaign={setCampaign}
                  personalization={personalization}
                  status={status}
                  campaign={campaign}
                  naics={naics}
                  gic={gic}
                  statusNew={statusNew}
                  handleOffNotification={handleOffNotification}
                  notification={notification}
                  id={id}
                />
              </Campaign>
            </>
          )}
        
        </>
      )}
    </Page>
  );
}
