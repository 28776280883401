import { useState, useEffect, useContext } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import RouterConfig from 'navigation/RouterConfig';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import store from 'store';
import theme from 'base/theme';
import MainWrapper from 'base/styled/MainWrapper';
import HeadContent from 'base/components/HeadContent';
import Responsive from 'context/responsive';
import ErrorBoundary from 'base/components/ErrorBoundary';
import MetaTags from 'base/components/Metatags';
import { AuthProvider } from 'base/components/AuthContext';
import { requestHistory } from 'base/network'; 

function App() {

  const ctx = useContext(Responsive);
  const [detail, setDetails] = useState(false);
  const [hitLimit, setHitLimit] = useState(ctx.hitLimit);
  const [navbar, setNavbar] = useState(false);
  const [impersonate, setImpersonate] = useState(false);
  const [isImpersonate, setIsImpersonate] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isTrial, setIsTrial] = useState(false);
  const [sunset, setSunset] = useState(false);
  const [isLiteFree, setIsLiteFree] = useState(false);
  const [role, setRole] = useState(null);
  const [active, setActive] = useState(true);
  const [showNewConversion, setShowNewConversion] = useState(false);
  const [hidden, setHidden] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  
  useEffect(() => {
    let impersonated = window.localStorage.getItem('IMPERSONATE');
    let user = JSON.parse(window.localStorage.getItem('USER'));
    window.addEventListener('resize', handleWindowSizeChange);
    setIsImpersonate(impersonated);
    setImpersonate(user);
    ctx.setIsPersonate(impersonated);
   
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
    /* eslint-disable */
  }, [isImpersonate]);
    /* eslint-enable */
  let stor = store.getState().accountReducer; 
  useEffect(() => {   
    setHitLimit(ctx.hitLimit);
    setIsLiteFree(store.getState().accountReducer.subscription.name == "Free lite plan");
    ctx.setIsLiteFree(store.getState().accountReducer.subscription.name == "Free lite plan");
    const diff = Date.parse(store.getState().accountReducer.details?.trial_end_date) - Date.now();
    setIsTrial(diff > 0 ? store.getState().accountReducer.details?.trial_end_date : false);
  }, [ctx, stor]);

  const isMobile = width <= 768;
  const isTablet = width <= 1100;

  const [isUserInteracted, setIsUserInteracted] = useState(false);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; 
    };

    if (isUserInteracted) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUserInteracted]);

  const handleUserInteraction = () => {
    setIsUserInteracted(true);
  };

  const [actionHistory, setActionHistory] = useState([]);

  const addAction = (action) => {
    setActionHistory((prevHistory) => {
      const newHistory = [action, ...prevHistory]; 
      if (newHistory.length > 5) {
        newHistory.pop();
      }
      return newHistory;
    });
  };

  const handleGlobalClick = (event) => {
    const element = event.target;
    const tagName = element.tagName.toLowerCase();

    if (tagName === 'button') {
      const action = `Button "${element.textContent}" clicked at ${new Date().toLocaleTimeString()}`;
      addAction(action); 
    } else if (tagName === 'a') {
      const action = `Link "${element.textContent}" clicked at ${new Date().toLocaleTimeString()}`;
      addAction(action); 
    } else if (tagName === 'input') {
      const action = `Input changed to "${element.value}" at ${new Date().toLocaleTimeString()}`;
      addAction(action);
    } else {
      const action = `Element "${tagName + ' ' + element.className}" clicked at ${new Date().toLocaleTimeString()}`;
      addAction(action);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleGlobalClick);

    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
    /* eslint-disable */
  }, []); 
    /* eslint-enable */

  return (
    <ErrorBoundary requestHistory={requestHistory} actionHistory={actionHistory}>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider>
          <CookiesProvider>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Provider store={store}>
                  <AuthProvider>
                    <Responsive.Provider
                      value={{
                        isMobile: isMobile,
                        isTablet: isTablet,
                        details: detail,
                        impersonate: impersonate,
                        setImpersonate: setImpersonate,
                        setDetails: setDetails,
                        isImpersonate: isImpersonate,
                        setIsPersonate: setIsImpersonate,
                        navbar: navbar,
                        setNavbar: setNavbar,
                        isTrial: isTrial,
                        setIsTrial: setIsTrial,
                        sunset: sunset,
                        setSunset: setSunset,
                        isLiteFree: isLiteFree,
                        setIsLiteFree: setIsLiteFree,
                        role: role,
                        setRole: setRole,
                        hitLimit: hitLimit,
                        setHitLimit: setHitLimit,
                        active: active,
                        setActive: setActive,
                        showNewConversion: showNewConversion,
                        setShowNewConversion: setShowNewConversion
                      }}
                    >
                      <MetaTags />
                      <MainWrapper
                        className={
                          ctx.isImpersonate || isLiteFree || isTrial || hitLimit
                            ? 'impersonate'
                            : ''
                        }
                      >
                        <HeadContent 
                          onClick={handleUserInteraction} 
                          impersonate={impersonate} 
                          setHidden={setHidden}
                        />
                        <RouterConfig hidden={hidden}/>
                      </MainWrapper>
                    </Responsive.Provider>
                  </AuthProvider>
                </Provider>
              </BrowserRouter>
            </ThemeProvider>
          </CookiesProvider>
        </HelmetProvider>
      </I18nextProvider>
    </ErrorBoundary>
  );
}

export default App;
